
import { Component, Prop, Vue } from "vue-property-decorator";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import { mixins } from "vue-class-component";
import ListMixin from "@/mixins/Crud/ListMixin";
import { TentStoreMixin } from "@/mixins/Stores/TentStoreMixin";
import { Plant, Tent } from "@/config/Modules";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IPlant } from "@/interfaces/models/IPlant";
import { ITent } from "@/interfaces/models/ITent";

@Component({
  components: { DefaultTableLayout },
})
export default class TentList extends mixins(ListMixin, TentStoreMixin) {
  @Prop({ required: false, default: "TentList" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "tent" })
  public descriptionField!: string;

  public get resource(): string {
    return Tent.resource;
  }

  protected loadList(options: ICrudOptions): void {
    this.findAllTentItemsAction(options);
  }

  protected onRowClick(row: ITent): void {
    if (row.id) {
      this.$router.push({ name: "TentShow", params: { id: String(row.id) } });
    }
  }

  public init() {}
}
